import Choices from "choices.js";

const elementsGated = document.body.querySelectorAll(".select");
if (document.querySelector(".select")) {
  elementsGated.forEach(function (elementGated) {
    const choicesGated = new Choices(elementGated, {
      searchEnabled: false,
      allowHTML: true
    });
  });
}

let gatedInsightForm = document.getElementById("gated_insight_form");
let gatedInsightEmailField = document.getElementById(
  "f_74fee5434d4ae811a305005056862e8d"
);
let gatedInsightVisitorEmail = document.getElementById("cd_visitoremail");
if (gatedInsightForm) {
  gatedInsightEmailField.addEventListener("keyup", e => {
    gatedInsightVisitorEmail.value = gatedInsightEmailField.value;
  });
}
if (gatedInsightForm) {
  var canSubmit = false;
  gatedInsightForm.addEventListener("submit", e => {
    e.preventDefault();
    if (isSecureGated()) {
      submitGatedForm();
    }
  });
}

function isSecureGated() {
  if (gatedInsightForm.classList.contains("isvalid")) {
    return true;
  } else {
    return false;
  }
}

function submitGatedForm() {
  let thisFormFields = getFormElements("gated_insight_form");
  if (formValidGated("gated_insight_form")) {
    fetch(gatedInsightForm.getAttribute("action"), {
      mode: "no-cors",
      method: gatedInsightForm.getAttribute("method"),
      body: new FormData(gatedInsightForm)
    }).then(res => checkGatedFormResponse(res));
  }
}

function checkGatedFormResponse(response) {
  if (response.ok == false) {
    var gatedContentForm = document.getElementById("gated_insight_form");
    var gatedContentFormId = gatedContentForm.dataset.formid;
    setCookie("had-gated-success-" + gatedContentFormId, "1", 999);
    document.getElementById("gated_insight_form").style.opacity = "0";
    document.getElementById("gated_form_response").innerHTML =
      '<div class="container"><h3 class="response">Thank you for requesting access. If the page does not reload automatically within ten seconds please refresh to see this exclusive content.</h3></div>';
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }
}

function getFormElements(formId) {
  var elements = document.getElementById(formId).elements;
  var obj = {};
  for (var i = 0; i < elements.length; i++) {
    var item = elements.item(i);
    obj[item.name] = item.value;
  }
  return JSON.stringify(obj);
}

function formValidGated(formToCheck) {
  let allAreFilled = true;
  document
    .getElementById(formToCheck)
    .querySelectorAll("[required]")
    .forEach(function (i) {
      if (!allAreFilled) return;
      if (i.type === "radio") {
        let radioValueCheck = false;
        document
          .getElementById("myForm")
          .querySelectorAll(`[name=${i.name}]`)
          .forEach(function (r) {
            if (r.checked) radioValueCheck = true;
          });
        allAreFilled = radioValueCheck;
        return;
      }
      if (!i.value) {
        allAreFilled = false;
        return;
      }
    });
  return allAreFilled;
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
