import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

if (document.body.classList.contains("gated")) {
  Fancybox.defaults = {
    ...Fancybox.defaults,
    backdropClick: false,
    dragToClose: false,
    touch: false
  };
  Fancybox.bind("[data-fancybox]", {
    backdropClick: false,
    maxHeight: 700,
    dragToClose: false,
    touch: false
    // Your custom options
  });
  setTimeout(() => {
    Fancybox.show([
      {
        src: "#gated_content_form",
        type: "inline",
        dragToClose: false,
        closeButton: false
      }
    ]);
  }, 5);
} else {
  Fancybox.defaults = {
    ...Fancybox.defaults,
    backdropClick: true,
    dragToClose: true,
    touch: true
  };
  Fancybox.bind("[data-fancybox]", {
    backdropClick: true,
    maxHeight: 968,
    dragToClose: true,
    touch: true
    // Your custom options
  });
}
