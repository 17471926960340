import Choices from "choices.js";

const elements = document.body.querySelectorAll(".select");
if (document.querySelector(".select")) {
  elements.forEach(function (element) {
    const choices = new Choices(element, {
      searchEnabled: false,
      allowHTML: true
    });
  });
}